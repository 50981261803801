import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

export interface Menu {
  headTitle1?: string;
  path?: string;
  title?: string;
  type?: string;
  active?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class SidebarService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  public search: boolean = false;
  public language: boolean = false;
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public horizontal: boolean = window.innerWidth < 991 ? false : true;
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });

    if (window.innerWidth < 991) {
      this.router.events.subscribe(() => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Administrator",
      type: "sub",
      active: true,
      children: [
        {
          title: "User",
          type: "sub",
          active: false,
          children: [
            { path: "admin/data-role", title: "Data Role", type: "link" },
            { path: "admin/data-user", title: "Data User", type: "link" },
          ],
        },
        {
          title: "OrgChart Generator",
          type: "sub",
          active: false,
          children: [
            {
              path: "org-chart/list",
              title: "Data OrgChart", type: "link"
            }
          ],
        },
        {
          title: "Module Generator",
          type: "sub",
          active: false,
          children: [{ path: "admin/data-modul", title: "Data Modul", type: "link" }],
        },
        {
          title: "SSO",
          type: "sub",
          active: false,
          children: [{ path: "admin/data-sso", title: "Data SSO", type: "link" }],
        },
        {
          title: "Pengaturan",
          type: "sub",
          active: false,
          children: [{ path: "admin/data-pengaturan", title: "Data Pengaturan", type: "link" }],
        },
      ],
    },
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
