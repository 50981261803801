import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as feather from "feather-icons";
import { LayoutService } from "../layout.service";
import { SidebarService } from "../sidebar/sidebar.service";
import { fadeInAnimation } from "../shared-layout/router-animation/router-animation";
import { UserService } from "@services/user.service";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit {
  protected isAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public navServices: SidebarService,
    public layout: LayoutService,
    private usrService: UserService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    switch (this.layout.config.settings.layout) {
      case "Dubai":
        return "compact-wrapper";
      default:
        return "compact-wrapper";
    }
  }

  ngOnInit() {
    this.usrService.isLoggedIn().subscribe((r) => {
      if (r) {
        this.usrService.getUserInfo().subscribe({
          next: (res) => {
            if (res && res.data && res.data.role_name == "superadmin") {
              this.isAdmin = true;
            }
          },
          error: (err) => { console.log(err); }
        });
      }
    });
  }
}
