<div class="report-container">
  <h1 class="report-title">Laporan Asset</h1>
  <button class="no-print fr btn text-black shadow" (click)="printReport()"><i class="fa fa-print"></i> Print</button>
  <div class="asset-description">
    <p><strong>Nama Asset:</strong> {{ data.asset?.name }}</p>
    <p><strong>Deskripsi:</strong> {{ data.asset?.description }}</p>
    <p><strong>Unit Kerja:</strong> {{ data.asset?.unker }}</p>
  </div>
  <table class="asset-detail-table">
    <thead>
      <tr>
        <th>Detail Item</th>
        <th>Deskripsi</th>
        <th>Unit Kerja</th>
        <th>Quantity</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detail of data.asset_detail">
        <td>{{ detail.name }}</td>
        <td>{{ detail.description }}</td>
        <td>{{ detail.kode_unker }}</td>
        <td>{{ detail.qty }}</td>
      </tr>
    </tbody>
  </table>
</div>