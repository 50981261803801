import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AisApiService } from "./ais-api.service";

@Component({
  selector: 'app-ais-api',
  templateUrl: './ais-api.component.html',
  styleUrls: ['./ais-api.component.scss']
})
export class AisApiComponent {
  private data: any = {
    endpoint: null,
    key: null,
  };
  protected isValid: boolean = false;
  protected jsonData: any;

  constructor(
    private route: ActivatedRoute,
    private service: AisApiService,
  ) {}

  ngOnInit(): void {
    this.data.endpoint = this.route.snapshot.params.endpoint;
    this.data.key = this.route.snapshot.queryParams.key;
    this.service.getApi(this.data.endpoint, this.data.key).subscribe({
      next: (res) => {
        this.jsonData = res;
        this.isValid = true;
      },
      error: (err) => {
        console.log(err);
        this.isValid = false;
      }
    });
  }
}
