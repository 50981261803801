import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CrudService } from "@services/crud.service";
import { Subscription } from "rxjs";
import { MainService } from "../../main/main.service";
import { SwalService } from "@services/swal.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-asset-list-cu",
  templateUrl: "./asset-list-cu.component.html",
  styleUrls: ["./asset-list-cu.component.scss"]
})
export class AssetListCuComponent implements OnInit, OnDestroy {
  @Input() hostID: string;

  private readonly FIELD = "asset-list";
  private id: string;
  private subs: Subscription;
  protected formData: FormGroup;
  protected unker: any[] = [];
  protected locationData: any[] = [];
  protected previewImage: string;
  provinsiData: any[];
  kabupatenData: any[];
  kecamatanData: any[];

  @Output() provinsiSelected = new EventEmitter<string>();
  @Output() kabupatenSelected = new EventEmitter<string>();
  @Output() kecamatanSelected = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private crud: CrudService,
    private mService: MainService,
    private sW: SwalService,
  ) {
    this.formData = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(150)]],
      description: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      kode_unker: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      old_image: [null], image: [null],
      available_qty: [null, Validators.required],
      available_total_time: [null, Validators.required],
      province: [null, Validators.required],
      city: [null, Validators.required],
      subdistrict: [null, Validators.required],
      location: [null],
      location_input: [null],
    });

    this.subs = this.crud.getID(1).subscribe((id) => {
      this.id = id; if (this.id) { this.loadFormData(); }
    });

    this.mService.getList('1', null).subscribe((data: any) => {
      this.provinsiData = data.data || [];
    });
  }

  ngOnInit(): void {
    this.formData.patchValue({
      id: this.hostID
    });

    this.loadUnker();
    this.loadLocation();
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  onRegionSelected(el: string, level: string, selectedCode: string) {
    if (el == 'prov') {
      this.provinsiSelected.emit(selectedCode);
    } else if (el == 'kab') {
      this.kabupatenSelected.emit(selectedCode);
    } else if (el == 'kec') {
      this.kecamatanSelected.emit(selectedCode);
      this.formData.patchValue({
        subdistrict: selectedCode
      });
    }

    this.mService.getList(level, selectedCode).subscribe((data: any) => {
      if (level == '1') {
        this.kabupatenData = [];
        this.kecamatanData = [];
      } else if (level == '2') {
        this.kabupatenData = data.data || [];
        this.kecamatanData = [];
      } else if (level == '3') {
        this.kecamatanData = data.data || [];
        this.formData.patchValue({
          subdistrict: null
        });
      }
    });
  }

  protected onFileSelected(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedFile: File = fileList[0];
      const reader = new FileReader();
      console.log("Selected file:", selectedFile.name);

      reader.onload = () => {
        this.previewImage = reader.result as string;
      };
      reader.readAsDataURL(selectedFile);

      if (this.id === null || !this.formData.get("old_image").value) {
        this.formData.patchValue({
          image: selectedFile,
          old_image: null,
        });
      } else {
        this.formData.patchValue({
          image: selectedFile,
        });
      }

      event.target.value = null;
    }
  }

  private loadUnker(): void {
    this.crud.getUnker().subscribe((res) => {
      console.log(res);
      if (res.status === 200) {
        this.unker = res.data;
      } else {
        Swal.fire({
          text: res.message,
          icon: "warning",
        });
      }
    }, (err) => {
      Swal.fire({
        text: err.error?.error || "An unexpected error occurred",
        icon: "warning",
      });
    });
  }

  private loadLocation(): void {
    this.crud.getLocation().subscribe((res) => {
      console.log(res);
      if (res.status === 200) {
        this.locationData = res.data;
      } else {
        Swal.fire({
          text: res.message,
          icon: "warning",
        });
      }
    }, (err) => {
      Swal.fire({
        text: err.error?.error || "An unexpected error occurred",
        icon: "warning",
      });
    });
  }

  private loadFormData(): void {
    this.crud.getDataByIDRS(this.FIELD, this.id).subscribe((res) => {
      const { name, description, kode_unker, subdistrict, } = res.data;
      const { provinceCode, cityCode, subdistrictCode } = this.parseSubdistrictCode(subdistrict);
      this.formData.patchValue({
        id: this.id,
        name: res.data.name,
        old_image: res.data.image,
        description: res.data.description,
        kode_unker: res.data.kode_unker,
        available_qty: res.data.available_qty,
        available_total_time: res.data.available_total_time,
        subdistrict: res.data.subdistrict,
        location: res.data.location,
      });

      this.previewImage = this.crud.e.asset_API + res.data.image;

      this.formData.patchValue({ province: provinceCode });

      this.mService.getList('2', provinceCode).subscribe((data: any) => {
        this.kabupatenData = data.data || [];
        this.formData.patchValue({ city: cityCode });
      });

      this.mService.getList('3', cityCode).subscribe((data: any) => {
        this.kecamatanData = data.data || [];
        this.formData.patchValue({ subdistrict: res.data.subdistrict });
      });
    });
  }

  private parseSubdistrictCode(subdistrict: string): { provinceCode: string, cityCode: string, subdistrictCode: string } {
    const parts = subdistrict.split('.');
    return {
      provinceCode: parts[0],
      cityCode: parts.slice(0, 2).join('.'),
      subdistrictCode: subdistrict
    };
  }

  protected doAddLocation(): void {
    if (this.formData.get("location_input").value == '' || this.formData.get("location_input").value == null) {
      Swal.fire({ text: "Silahkan input nama lokasi", icon: "warning" });
      return;
    }

    Swal.fire({
      text: "Tambah data lokasi ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const form: any = {
          name: this.formData.get("location_input")?.value,
        };

        this.crud.createDataRS("location", form).subscribe({
          next: (res) => {
            if (res.status == 200) {
              Swal.fire({ text: "Berhasil dibuat", icon: "success" });
              this.loadLocation();
            } else {
              Swal.fire({
                text: res.error,
                icon: "warning",
              });
            }
          },
          error: (err) => {
            Swal.fire({
              text: err.error.error,
              icon: "warning",
            });
          },
        });
      }
    });
  }

  protected doDeleteLocation(): void {
    if (this.formData.get("location").value == '' || this.formData.get("location").value == null) {
      Swal.fire({ text: "Silahkan pilih lokasi", icon: "warning" });
      return;
    }

    Swal.fire({
      text: "Hapus data lokasi (hanya bisa lokasi yang anda buat) ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.crud.deleteData1IDRS("location", parseInt(this.formData.get("location")?.value)).subscribe({
          next: (res) => {
            if (res.status == 200) {
              Swal.fire({ text: "Berhasil dihapus", icon: "success" });
              this.loadLocation();
            }
          },
          error: (err) => {
            console.error(err);
            Swal.fire({ text: "Gagal dihapus karena bukan dicreate oleh anda", icon: "warning" });
          },
        });
      }
    });
  }

  onLocationInputChange(event: any): void {
    const input = event.target.value;
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
    this.formData.controls['location_input'].setValue(filteredInput);
  }

  protected doSave(): void {
    if (this.formData.invalid) {
      this.sW.wSwal("Silahkan input semua form yang diwajibkan isi sebelum menyimpan.");
      return;
    }

    Swal.fire({
      text: "Simpan data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const imgData = new FormData();
        const imageFile: File = this.formData.get("image").value;

        const form: any = {
          name: this.formData.get("name").value,
          description: this.formData.get("description").value,
          kode_unker: this.formData.get("kode_unker").value,
          available_qty: this.formData.get("available_qty").value,
          available_total_time: this.formData.get("available_total_time").value,
          subdistrict: this.formData.get("subdistrict").value,
          location: parseInt(this.formData.get("location").value),
        };
        if (this.id) {
          form.id = this.id;
          if (imageFile) {
            imgData.append("id", this.id);
            imgData.append("image", imageFile);
            imgData.append("old_image", this.formData.get("old_image").value);
            this.crud.saveImgRS("asset-management", imgData).subscribe((res) => {
              form.image = res.data;
              this.crud.updateDataRS(this.FIELD, form).subscribe({
                next: (res) => {
                  if (res.status == 200) {
                    this.crud.toggleVisible(1);
                    this.crud.triggerLoadData(1);
                  } else {
                    Swal.fire({
                      text: res.message,
                      icon: "warning",
                    });
                  }
                },
                error: (err) => {
                  Swal.fire({
                    text: err.error?.error || "An unexpected error occurred",
                    icon: "warning",
                  });
                },
              });
            });
          } else {
            form.image = this.formData.get("old_image").value,
              this.crud.updateDataRS(this.FIELD, form).subscribe({
                next: (res) => {
                  if (res.status == 200) {
                    this.crud.toggleVisible(1);
                    this.crud.triggerLoadData(1);
                  } else {
                    Swal.fire({
                      text: res.message,
                      icon: "warning",
                    });
                  }
                },
                error: (err) => {
                  Swal.fire({
                    text: err.error?.error || "An unexpected error occurred",
                    icon: "warning",
                  });
                },
              });
          }
        } else {
          if (imageFile) {
            imgData.append("id", this.id);
            imgData.append("image", imageFile);
            this.crud.saveImgRS("asset-management", imgData).subscribe((res) => {
              form.image = res.data;
              this.crud.createDataRS(this.FIELD, form).subscribe({
                next: (res) => {
                  if (res.status == 200) {
                    this.crud.toggleVisible(1);
                    this.crud.triggerLoadData(1);
                  } else {
                    Swal.fire({
                      text: res.error,
                      icon: "warning",
                    });
                  }
                },
                error: (err) => {
                  Swal.fire({
                    text: err.error.error,
                    icon: "warning",
                  });
                },
              });
            });
          } else {
            this.crud.createDataRS(this.FIELD, form).subscribe({
              next: (res) => {
                if (res.status == 200) {
                  this.crud.toggleVisible(1);
                  this.crud.triggerLoadData(1);
                } else {
                  Swal.fire({
                    text: res.error,
                    icon: "warning",
                  });
                }
              },
              error: (err) => {
                Swal.fire({
                  text: err.error.error,
                  icon: "warning",
                });
              },
            });
          }
        }
      }
    });
  }

  protected doCancel(): void {
    this.crud.toggleVisible(1);
  }
}
