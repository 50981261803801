import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AssetManagementService } from "../assets-management.service";
import { UserService } from "@services/user.service";
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { I18nPluralPipe } from "@angular/common";
import { CalendarEventTitleFormatter } from "angular-calendar";
import { env } from "@env/env";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useFactory: adapterFactory
    },
    CalendarEventTitleFormatter,
    I18nPluralPipe
  ]
})
export class MainComponent implements OnInit {
  allowedDomains: string[] = [];
  dataKey: string | null = null;
  isEmbedded: boolean = false;
  routerLinkSuffix: string = "";
  constructor(
    private route: ActivatedRoute,
    private service: AssetManagementService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    if (window.top !== window.self) {
      this.isEmbedded = true;
      this.routerLinkSuffix = "-access";
      this.validateIframeEmbedding();
    } else {
      this.isEmbedded = false;
      this.routerLinkSuffix = "";
      this.checkLocalStorage();
    }
  }

  validateIframeEmbedding(): void {
    this.route.queryParams.subscribe(params => {
      const dataKey = params["data-key"];
      if (dataKey) {
        this.dataKey = dataKey;
        this.service.getAllowedDomain(dataKey).subscribe({
          next: (res) => {
            this.allowedDomains = res.data;
            const parentDomain = new URL(document.referrer).hostname;
            if (!this.allowedDomains.includes(parentDomain)) {
              document.body.innerHTML = "Embedding not allowed.";
              return;
            }
            this.setCookie("ppt-asset-data-key", this.dataKey, 1);
          },
          error: (err) => {
            console.log(err);
            document.body.innerHTML = "Embedding not allowed.";
            return;
          }
        });
      } else {
        document.body.innerHTML = "Missing data-key.";
      }
    });
  }

  private setCookie(name: string, value: string, hours: number): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

    let expires = "";
    if (hours) {
      const date = new Date();
      date.setTime(date.getTime() + (hours * 60 * 60 * 168000)); // Convert hours to milliseconds
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
  }

  checkLocalStorage(): void {
    this.userService.isLoggedIn().subscribe((res) => {
      res ?
        this.setCookie("ppt-asset-data-key", env.assetManagementKey, 1) :
        document.body.innerHTML = "Authentication required.";
    });
  }
}
