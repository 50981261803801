<p-toast [styleClass]="{'p-toast-message': true}"></p-toast>

<!-- Password Input Box -->
<div *ngIf="isPassword" class="password-box">
    <div class="password-box-content">
        <div class="row">
            <div class="col-lg-12 header-content">
                <a routerLink="/" class="logo mt-3">
                    <img width="50" src="assets/images/logo/logo.png" alt="Logo" />
                </a>
                <div class="separator"></div>
                <form (ngSubmit)="verifyPassword()">
                    <div class="form-group ml-2">
                        <div class="input-group">
                            <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
                            <input
                            class="form-control bg-light-primary"
                            type="password"
                            placeholder="Password"
                            [(ngModel)]="password"
                            name="password"
                            required=""
                            />
                        </div>
                        <button type="submit" class="btn btn-primary mt-2">Submit Password</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Header Panel and Organization Chart -->
<div *ngIf="!isPassword">
    <div class="header-panel">
        <div class="row">
            <div class="col-lg-12 header-content">
                <a routerLink="/" class="logo">
                    <img width="50" src="assets/images/logo/logo.png" alt="Logo" />
                </a>
                <div class="separator"></div>
                <div class="header-text">
                    <h5>{{ info?.title }}</h5>
                    <p>{{ info?.description }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="org-background">
        <div class="org-background-panel">
            <p-organizationChart
                [value]="data1"
                selectionMode="single"
                [(selection)]="selectedNode"
                (onNodeSelect)="onNodeSelect($event)"
                styleClass="company">
                <ng-template let-node pTemplate="person">
                    <div
                        class="node-content"
                        [ngStyle]="{'background-color': node.data.backgroundColor || '#495ebb'}"
                        title="{{node.data.description || ''}}"
                        [pTooltip]="tooltipContent"
                        tooltipPosition="right"
                        (click)="onNodeClick(node)">
                        <ng-template #tooltipContent>
                            <div class="flex align-items-center">
                                <strong>Name:</strong> {{node.data.name}}<br>
                                <strong>Label:</strong> {{node.label}}<br>
                                <div *ngIf="node.data.url"><strong>URL:</strong> <a class="text-white" href="{{node.data.url}}" target="_blank"> {{node.data.url}}</a><br></div>
                                <strong>Description:</strong> {{node.data.description}}<br>
                            </div>
                        </ng-template>
                        <img *ngIf="node.data.avatar" [src]="node.data.avatar" alt="{{node.data.name}}" class="node-image" loading="lazy">
                        <div *ngIf="node.label" class="node-header" [ngStyle]="{'background-color': '#000'}">
                            {{node.label}}
                        </div>
                        <div class="node-name">{{node.data.name}}</div>
                    </div>
                    <div class="custom-line-container">
                        <div class="custom-horizontal-line" *ngIf="node.children && node.children.length > 0"></div>
                    </div>
                </ng-template>
                <ng-template let-node pTemplate="department">
                    <div class="custom-line-container">
                        <div class="custom-horizontal-line" *ngIf="node.children && node.children.length > 0"></div>
                        {{node.label}}
                    </div>
                </ng-template>
            </p-organizationChart>
        </div>
    </div>
</div>
