import { Component } from '@angular/core';

@Component({
  selector: 'app-book-attendance',
  templateUrl: './book-attendance.component.html',
  styleUrls: ['./book-attendance.component.scss']
})
export class BookAttendanceComponent {

}
