import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, of } from "rxjs";
import { CrudService } from "@services/crud.service";
import { SwalService } from "@services/swal.service";
import Swal from "sweetalert2";
import { env } from "@env/env";

@Component({
  selector: "app-asset-list-detail",
  templateUrl: "./asset-list-detail.component.html",
  styleUrls: ["./asset-list-detail.component.scss"]
})
export class AssetListDetailComponent {
  @Input() assetID: string;
  @Input() assetName: string;
  @Input() modal: NgbModalRef;
  @Output() reserved = new EventEmitter<void>();

  private readonly FIELD = "asset-list-detail";
  private totalRecords: number = 0;
  private sortedColumnIndex: number | null = null;
  protected data: any[] = [];
  protected filter: any = {
    search: "", sortBy: "id", sortOrder: "desc", page: 1, pageSize: 10,
  };
  protected currentPage: number = 1;
  protected pageSize: number = 10;
  protected totalPages: number = 0;
  protected selectedPageSize: number = 10;
  protected sortE: string = env.sort;
  protected isDelete: boolean = false;
  protected visible: boolean = false;
  protected action: string = "";
  protected showSkeleton: boolean = true;
  protected formData: FormGroup;
  protected unker: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private service: CrudService,
    private sW: SwalService
  ) {
    this.formData = this.formBuilder.group({
      id: [null],
      asset_id: [null],
      name: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      qty: [null, Validators.required],
      description: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      kode_unker: [null, [Validators.required]],
    });
  }

  close(): void {
    this.modal.dismiss();
  }

  ngOnInit(): void {
    this.service.visibleSection1Changed.subscribe((value) => {
      this.visible = value;
    });

    this.service.getLabel(2).subscribe((label) => {
      this.action = label;
    });

    this.service.loadData$.subscribe(() => {
      this.loadData();
    });

    this.loadData();

    setTimeout(() => {
      this.showSkeleton = false;
    }, 1000);

    this.loadUnker();
  }

  private loadUnker(): void {
    this.service.getUnker().subscribe((res) => {
      if (res.status === 200) {
        this.unker = res.data;
      } else {
        Swal.fire({
          text: res.message,
          icon: "warning",
        });
      }
    }, (err) => {
      Swal.fire({
        text: err.error?.error || "An unexpected error occurred",
        icon: "warning",
      });
    });
  }

  protected doSave(): void {
    if (this.formData.invalid) {
      this.sW.wSwal("Silahkan input semua form yang diwajibkan isi sebelum menyimpan.");
      return;
    }

    Swal.fire({
      text: "Simpan data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const form: any = {
          asset_id: this.assetID,
          name: this.formData.get("name").value,
          description: this.formData.get("description").value,
          kode_unker: this.formData.get("kode_unker").value,
          qty: this.formData.get("qty").value,
        };

        this.service.createDataRS(this.FIELD, form).subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.loadData();
              this.formData.reset();
              Swal.fire({ text: "Data berhasil disimpan", icon: "success" });
            } else {
              Swal.fire({ text: res.error, icon: "warning" });
            }
          },
          error: (err) => {
            Swal.fire({ text: err.error.error, icon: "warning" });
          },
        });
      }
    });
  }

  private loadData(): void {
    this.service.getListIDRS(this.FIELD, this.filter, this.assetID).subscribe({
      next: (res) => {
        this.data = res.data.rows;
        this.totalRecords = res.data.meta.total;
        this.calculateTotalPages();
      },
      error: (err) => { console.log(err); },
    });
  }

  protected calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
  }

  protected onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.filter.page = newPage;
    this.loadData();
  }

  protected onPageSizeChange(newPageSize: number): void {
    this.pageSize = newPageSize;
    this.currentPage = 1;
    this.filter.page = this.currentPage;
    this.filter.pageSize = newPageSize;
    this.loadData();
  }

  protected getPagesArray(): number[] {
    const totalVisiblePages = 5;
    const pagesArray: number[] = [];
    const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2));
    const endPage = Math.min(this.totalPages, startPage + totalVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pagesArray.push(i);
    }

    return pagesArray;
  }

  protected sort(columnIndex: number, str: string): void {
    if (this.filter.sortBy === str) {
      this.filter.sortOrder = this.filter.sortOrder === "asc" ? "desc" : "asc";
    } else {
      this.filter.sortOrder = "desc";
      this.filter.sortBy = str;
    }
    this.sortedColumnIndex = columnIndex;
    this.loadData();
  }

  protected changePageSize(): void {
    this.filter.pageSize = this.selectedPageSize;
    this.loadData();
  }

  protected isSorted(columnIndex: number): boolean {
    return this.sortedColumnIndex === columnIndex;
  }

  protected search(term: string): Observable<any[]> {
    if (term.length <= 0 || term.length >= 3) {
      this.filter.search = term.toLocaleLowerCase();
      this.loadData();
    }

    return of([]);
  }

  protected doDelete(id: string): void {
    Swal.fire({
      title: "Hapus Data ?",
      text: "Yakin hapus data yang anda pilih?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteDataIDRS(this.FIELD, id).subscribe({
          next: () => {
            this.loadData();
          },
          error: (err) => {
            console.error(err);
          },
        });
      }
    });
  }

  protected report(id: string): void {
    const domain = window.location.origin;
    const url = domain + "/asset-report/" + id;
    navigator.clipboard.writeText(url).then(() => {
      this.sW.sSwal("URL copied to clipboard: " + url);
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  }
}
