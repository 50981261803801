import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject, Output, EventEmitter } from "@angular/core";
import { isPlatformBrowser, DOCUMENT, DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { CrudService } from "@services/crud.service";
import { Router } from '@angular/router';
import { env } from "@env/env";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-my-url-list',
  templateUrl: './my-url-list.component.html',
  styleUrls: ['./my-url-list.component.scss']
})
export class MyUrlListComponent implements OnInit, OnDestroy {
  @ViewChild("myUrlListModal", { static: false }) myUrlListModal: TemplateRef<any>;
  @Output() dataDeleted: EventEmitter<void> = new EventEmitter<void>();
  private readonly FIELD = "url-shortener";
  private selectedIds: string[] = [];
  private selectedNames: string[] = [];
  protected modalTitle: string = "URL Shorten";
  protected moduleID: string;
  protected isSection: boolean;
  protected closeResult: string;
  protected modalOpen: boolean = false;
  protected isDelete: boolean;
  protected data: any[] = [];
  protected apiUrl: string = env.v1_API;
  protected newItem: boolean = false;
  protected baseUrl = this.document.location.origin;

  protected visibleSection1: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private service: CrudService,
    private toast: ToastrService,
    private datePipe: DatePipe,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.service.visibleSection1Changed.subscribe((value) => {
      this.visibleSection1 = value;
    });

    this.service.loadDataSection1$.subscribe(() => {
      this.loadData();
    });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  private loadData(): void {
    this.service.getListOnly(this.FIELD, this.moduleID).subscribe({
      next: (res) => {
        this.data = res.data.map(item => ({
          ...item,
          expiredFormatted: this.datePipe.transform(item.expired, 'dd/MM/yyyy') // Format date
        }));
        this.newItem = true;
        setTimeout(() => {
          this.newItem = false;
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public openModal() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadData();
      this.modalService.open(this.myUrlListModal, {
        size: "lg",
        ariaLabelledBy: "modal-bookmark",
        centered: true,
        windowClass: "modal-bookmark"
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // CRUD
  protected resetIDs(): void {
    this.isDelete = false;
    this.selectedIds = [];
  }

  protected createURL(): void {
    this.router.navigate(['/url-shortener']).then(success => {
      if (success) {
        this.modalService.dismissAll();
      }
    });
  }

  protected doDelete(): void {
    if (this.selectedIds.length > 0) {
      Swal.fire({
        title: "Hapus Data?",
        text: "Yakin hapus data yang anda pilih?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.service.deleteData(this.FIELD, this.selectedIds).subscribe({
            next: () => {
              this.selectedIds = [];
              this.selectedNames = [];
              this.isDelete = this.selectedIds.length > 0;
              this.loadData();
              this.dataDeleted.emit();
            },
            error: (err) => {
              console.error(err);
            },
          });
        }
      });
    }
  }

  protected onCheckboxChange(id: string, name: string): void {
    const index = this.selectedIds.indexOf(id);
    const nameIndex = this.selectedNames.indexOf(name);
    if (index === -1) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds.splice(index, 1);
    }

    if (nameIndex === -1) {
      this.selectedNames.push(name);
    } else {
      this.selectedNames.splice(nameIndex, 1);
    }

    this.isDelete = this.selectedNames.length > 0;
  }

  protected isSelected(name: string): boolean {
    return this.selectedNames.includes(name);
  }

  protected async doCopy(endpoint: string) {
    try {
      await navigator.clipboard.writeText(`${this.baseUrl}/s/${endpoint}`);
      this.toast.success('URL berhasil dicopy!', 'Success', {timeOut: 500});
    } catch (err) {
      this.toast.error('Could not copy URL', 'Error', {timeOut: 500});
    }
  }
}
