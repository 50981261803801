import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { SidebarService } from "../sidebar/sidebar.service";
import { LayoutService } from "../layout.service";
import { UserService } from "src/app/_services/user.service";
import { HeaderService } from "./header.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  protected elem: any;
  protected isLoggedIn: boolean = false;
  protected isAdmin: boolean = false;
  protected latitude: number;
  protected longitude: number;
  protected weatherData: any = [];

  constructor(
    protected layout: LayoutService,
    protected navServices: SidebarService,
    private usrService: UserService,
    private headerService: HeaderService,
    @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit() {
    this.elem = document.documentElement;

    this.usrService.isLoggedIn().subscribe((r) => {
      if (r) {
        this.usrService.getUserInfo().subscribe({
          next: (res) => {
            if (res && res.data && res.data.role_name == "superadmin") {
              this.isAdmin = true;
            }
          },
          error: (err) => { console.log(err); }
        });
        this.isLoggedIn = true;
      }
    });
  }

  private getWeather() {
    this.headerService.getWeather(this.latitude, this.longitude).subscribe((data) => {
      this.weatherData = data;
    });
  }

  private getFormattedDate(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };

    return currentDate.toLocaleDateString('id-ID', options);
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    }
  }
}
