import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { OrgChartService } from './org-chart.service';
import { env } from "@env/env";

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  providers: [MessageService],
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit {
  protected data1: TreeNode[];
  protected selectedNode: TreeNode;
  protected id: string;
  protected isPassword: boolean = false;
  protected info: any = {};
  protected password: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private orgService: OrgChartService
  ) {
    this.id = this.route.snapshot.params.id;
    this.orgService.getChart(this.id).subscribe({
      next: (res) => {
        console.log(res);
        this.isPassword = res.data.is_password;
        if (!this.isPassword) {
          const chartDataDetailExists = res.data?.chart_data_detail && res.data.chart_data_detail.length > 0;
          if (!chartDataDetailExists) {
            this.router.navigate(['/']);
          }
          this.data1 = this.mapChartDataDetail(res.data.chart_data_detail);
          this.info = res.data.chart_data;
        }
      },
      error: (err) => {
        console.log(err);
        this.router.navigate(['/']);
      }
    });
  }

  mapChartDataDetail(chartDataDetail: any[]): TreeNode[] {
      const nodeMap = new Map<string, TreeNode>();

      chartDataDetail.forEach((detail) => {
          const nodeData: any = {};
          if (detail.detail_title) {
              nodeData.name = detail.detail_title;
          }
          if (detail.detail_color) {
              nodeData.backgroundColor = detail.detail_color;
          }
          if (detail.detail_img) {
              nodeData.avatar = `${env.asset_API}${detail.detail_img}`;
          }
          if (detail.detail_description) {
              nodeData.description = detail.detail_description;
          }
          if (detail.detail_url) {
              nodeData.url = detail.detail_url;
          }

          const node: TreeNode = {
              label: detail.detail_label || 'Unknown',
              type: 'person',
              styleClass: 'p-person',
              expanded: true,
              data: nodeData,
              children: []
          };

          nodeMap.set(detail.id, node);
      });

      const rootNodes: TreeNode[] = [];

      // Second pass: Link child nodes to their parent nodes using `relational_id`
      chartDataDetail.forEach((detail) => {
          const node = nodeMap.get(detail.id);
          if (detail.relational_id) {
              const parentNode = nodeMap.get(detail.relational_id);
              if (parentNode) {
                  parentNode.children.push(node);
              }
          } else {
              rootNodes.push(node);
          }
      });

      // Sort root nodes to ensure the `is_master` node is the first one
      rootNodes.sort((a, b) => {
          const aIsMaster = chartDataDetail.find(detail => detail.id === a.data.name)?.is_master;
          const bIsMaster = chartDataDetail.find(detail => detail.id === b.data.name)?.is_master;
          return aIsMaster ? -1 : bIsMaster ? 1 : 0;
      });

      return rootNodes;
  }

  ngOnInit() { }

  verifyPassword() {
    if (!this.password) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Password cannot be empty' });
      return;
    }

    this.orgService.verifyPassword(this.id, this.password).subscribe({
      next: (res) => {
        if (!res.data.is_password) {
          this.isPassword = false;
          const chartDataDetailExists = res.data?.chart_data_detail && res.data.chart_data_detail.length > 0;
          if (!chartDataDetailExists) {
            this.router.navigate(['/']);
          }
          this.data1 = this.mapChartDataDetail(res.data.chart_data_detail);
          this.info = res.data.chart_data;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid password' });
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to verify password' });
      }
    });
  }

  onNodeSelect(event) {
    if (event.node.data.url.trim() !== "") {
      navigator.clipboard.writeText(event.node.data.url).then(() => {
      this.messageService.add({ severity: 'success', summary: 'URL Copied', detail: event.node.data.url });
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to copy URL' });
    });
    }
  }
}
