import { Component } from '@angular/core';

@Component({
  selector: 'app-book-asset',
  templateUrl: './book-asset.component.html',
  styleUrls: ['./book-asset.component.scss']
})
export class BookAssetComponent {

}
