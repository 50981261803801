import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      icon: 'stroke-svg',
    },
    color: {
      primary_color: '#006600',
      secondary_color: '#f73164',
    },
  };
}
