import { Injectable } from "@angular/core";
import { AES, enc } from "crypto-js";
import { env } from "@env/env";
import { CookieService } from "ngx-cookie-service";

const USER_KEY = "auth-user";
const SESSION_EXPIRATION_KEY = "session-expiration";
const PPT_SSO_MODE_COOKIE = "ppt_sso_mode";
const PPT_KEY_ACCESS_COOKIE = "ppt_key_access";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private cookieService: CookieService) {}

  clean(): void {
    window.sessionStorage.clear();
  }

  public isValidSSO(): boolean {
    const pptSSOMode = this.cookieService.get(PPT_SSO_MODE_COOKIE);
    const pptKeyAccess = this.cookieService.get(PPT_KEY_ACCESS_COOKIE);

    if (!pptSSOMode) {
      return true;
    } else if (pptSSOMode && pptKeyAccess) {
      return true;
    } else {
      return false;
    }
  }

  public saveUser(user: any): void {
    const encData = AES.encrypt(JSON.stringify(user), env.qu2h67l8).toString();
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, encData.toString());

    let expirationTimestamp = new Date();
    if (user.data.remaining_ttl) {
      const ttlParts = user.data.remaining_ttl.match(/(\d+)\s*(m)?(\d+)?\s*(s)?/);
      if (ttlParts) {
        let milliseconds = 0;
        if (ttlParts[2]) {
          milliseconds += parseInt(ttlParts[1]) * 60 * 1000;
        }
        if (ttlParts[3]) {
          milliseconds += parseInt(ttlParts[3]) * 1000;
        }
        expirationTimestamp = new Date(Date.now() + milliseconds);
      } else {
        console.error('Invalid remaining_ttl format:', user.remaining_ttl);
        expirationTimestamp.setHours(expirationTimestamp.getHours() + 1);
      }
    } else {
      this.cookieService.delete(PPT_SSO_MODE_COOKIE, "/");
      expirationTimestamp.setHours(expirationTimestamp.getHours() + 1);
    }

    window.sessionStorage.setItem(SESSION_EXPIRATION_KEY, expirationTimestamp.getTime().toString());
  }

  public getExpirationTimestamp(): number | null {
    const sessionExpiration = window.sessionStorage.getItem(SESSION_EXPIRATION_KEY);
    return sessionExpiration ? parseInt(sessionExpiration, 10) : null;
  }

  public getUser(plain: boolean): any | null {
    const user = window.sessionStorage.getItem(USER_KEY);
    const expirationTimestamp = this.getExpirationTimestamp();

    if (user && expirationTimestamp && expirationTimestamp > new Date().getTime()) {
      if (plain) {
        try {
          const decData = AES.decrypt(user, env.qu2h67l8);
          const userData = JSON.parse(decData.toString(enc.Utf8));
          return userData;
        } catch (error) {
          console.error("Error decrypting user data: ", error);
        }
      } else {
        return user;
      }
    }
    this.clean();
    return null;
  }

  public isLoggedIn(): boolean {
    return !!this.getUser(false);
  }
}
