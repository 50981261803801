import { Routes } from "@angular/router";
import { RoleGuard } from "../_guard/role.guard";

export const contentRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "regulasi",
    loadChildren: () => import("../pages/regulation/regulation.module").then((m) => m.RegulationModule),
  },
  {
    path: "layanan",
    loadChildren: () => import("../pages/service/service.module").then((m) => m.ServiceModule),
  },
  {
    path: "url-shortener",
    loadChildren: () => import("../pages/url-shortener/url-shortener.module").then((m) => m.UrlShortenerModule),
    canActivate: [RoleGuard],
  },
  {
    path: "profile",
    loadChildren: () => import("../pages/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [RoleGuard],
  },
  {
    path: "",
    loadChildren: () => import("../pages/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [RoleGuard],
  },
  {
    path: "",
    loadChildren: () => import("../pages/assets-management/assets-management.module").then((m) => m.AssetsManagementModule),
    canActivate: [RoleGuard],
  },
];
