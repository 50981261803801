<div class="card">
  <div class="card-header card-no-border">
    <div class="header-top d-flex justify-content-center align-items-center">
      <h6><i class="fa fa-url"></i> URL Shortener</h6>
    </div>
    <form style="margin-bottom: 10px" class="form theme-form" [formGroup]="urlForm">
      <div class="container mt-3">
        <div class="row">
          <div class="mb-3 m-form__group col-lg-12">
            <label class="form-label">Input URL <small class="font-warning"><i>* wajib, min 4 karakter</i></small></label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-link"></i></span>
              <input
                class="form-control gf_border bg-light-info"
                type="text"
                placeholder="Long URL"
                name="url"
                formControlName="url"/>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-8">
            <label class="form-label">Endpoint</label>
            <div class="input-group">
              <span class="input-group-text">{{ baseUrl }}/s/</span>
              <input
                class="form-control gf_border bg-light-info"
                type="text"
                placeholder="URL custom / bisa dikosongkan"
                name="endpoint"
                [(ngModel)]="endpoint"
                (keyup)="onEndpointKeyUp()"
                (change)="onEndpointChange()"
                formControlName="endpoint"/>
            </div>
            <small class="font-primary"><i>(jika dikosongkan, akan create random 6 digit)</i></small>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Expired Pada <small class="font-warning"><i>* wajib</i></small></label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-calendar"></i></span>
              <input
                class="form-control gf_border bg-light-info"
                type="date"
                placeholder="expired"
                name="expired"
                formControlName="expired"/>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-9">
            <label class="form-label">Deskripsi</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input
                class="form-control gf_border bg-light-info"
                type="text"
                placeholder="deskripsi mengenai url apa, untuk mempermudah, boleh dikosongkan"
                name="description"
                formControlName="description"/>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-2">
            <label class="form-label"></label>
            <div class="input-group mt-2">
              <button (click)="doGenerate();" class="btn btn-info fw-bold gf-wrap-text ng-star-inserted">
                <i class="fa fa-floppy-o"></i>
                Shorten URL
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
