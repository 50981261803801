import { Component, ViewChild, OnInit } from "@angular/core";
import { SidebarService } from "../sidebar/sidebar.service";
import { LayoutService } from "../layout.service";
import { UserService } from "src/app/_services/user.service";
import { MyUrlListComponent } from "./modal/my-url-list/my-url-list.component";
import { ModalService } from "@services/modal.service";
import { MyTourService } from '@services/tour.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: "app-floating-menu",
  templateUrl: "./floating-menu.component.html",
  styleUrls: ["./floating-menu.component.scss"],
})
export class FloatingMenuComponent implements OnInit {
  @ViewChild("myUrlListModal") myUrlListModal: MyUrlListComponent;

  public elem: any;
  isLoggedIn: boolean = false;
  isComplete: boolean = false;
  isDashboard: boolean = false;

  constructor(
    public layout: LayoutService,
    public navServices: SidebarService,
    private usrService: UserService,
    private modalService: ModalService,
    private tourService: MyTourService,
    private router: Router
  ) { }

  updateIsDashboard() {
    this.isDashboard = this.router.url === '/dashboard';
  }

  ngOnInit() {
    this.usrService.isLoggedIn().subscribe((r) => {
      if (r) { this.isLoggedIn = true; }
      this.updateIsDashboard();
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.updateIsDashboard();
    });

    this.modalService.openModal$.subscribe(() => {
      this.myUrlListModal.openModal();
    });
  }

  showTourGuide() {
    this.tourService.initializeTour();
  }
}
