import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { env } from "@env/env";

@Injectable({
  providedIn: "root"
})
export class OrgChartService {

  constructor(
    private http: HttpClient,
  ) {}

  public getChart(id: string): Observable<any> {
    let params = new HttpParams();
    const url = `${env.v1_API}org-chart`;
    params = params.set("chartID", id);
    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "json",
      params: params,
    });
  }

  public verifyPassword(id: string, password: string): Observable<any> {
    let params = new HttpParams();
    const url = `${env.v1_API}org-chart-verify-password`;
    params = params.set("chartID", id);
    params = params.set("password", password);
    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "json",
      params: params,
    });
  }
}
