import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { env } from '@env/env';

@Component({
  selector: 'app-fullscreen-image',
  templateUrl: './fullscreen-image.component.html',
  styleUrls: ['./fullscreen-image.component.scss']
})
export class FullscreenImageComponent {
  protected assetURL: string = env.asset_API;
  protected imageURL: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.imageURL = this.route.snapshot.params.imageUrl;
  }
}
