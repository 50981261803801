import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from "sweetalert2";

@Injectable({
  providedIn: 'root',
})
export class SwalService {
    confirmSwal(
        message: string,
        confirmButtonText: string,
        cancelButtonText: string,
        onConfirm: () => void,
        onDismiss:() => void
    ): void {
        Swal.fire({
            text: message,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: {
                popup: 'my-swal-popup',
                title: 'my-swal-title',
                htmlContainer: 'my-swal-text',
                icon: 'my-swal-icon',
                confirmButton: 'my-swal-confirm-button',
                cancelButton: 'my-swal-cancel-button',
            },
        }).then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                onConfirm();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                onDismiss();
            }
        });
    }

    private showSwal(message: string, icon: 'success' | 'error' | 'warning' | 'info'): void {
        Swal.fire({
          text: message,
          icon: icon,
          customClass: {
            popup: 'my-swal-popup',
            title: 'my-swal-title',
            htmlContainer: 'my-swal-text',
            icon: 'my-swal-icon',
            confirmButton: 'my-swal-confirm-button',
            cancelButton: 'my-swal-cancel-button',
          },
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }

      sSwal(message: string): void {
        this.showSwal(message, 'success');
      }

      eSwal(message: string): void {
        this.showSwal(message, 'error');
      }

      wSwal(message: string): void {
        this.showSwal(message, 'warning');
      }

      iSwal(message: string): void {
        this.showSwal(message, 'info');
      }
}
