<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card shadow">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <h5 class="ribbon ribbon-bookmark ribbon-primary fw-bold">Asset Management</h5>
                        <p class="ribbon ribbon-clip-bottom-right ribbon-primary ribbon-right" style="padding: 2px 6px 2px 6px">
                            <img class="mb-1" width="20" src="assets/images/logo/logo.png">
                            Kementerian Pertanian Republik Indonesia
                        </p>
                        <div class="card-body mt-4 mb-2">
                            <div class="row">
                                <div class="col-lg-12 col-12">
                                    <div class="row mb-3 d-flex justify-content-center">
                                        <div class="col-lg-4 col-5 user-image bg-card-1 mb-2">
                                            <a [routerLink]="['/asset-management-supplier']" routerLinkActive="router-link-active" >
                                                <div class="image-container">
                                                    <img width="100" class="top-right-img" src="assets/supplier.png" alt="ghaly fadhillah">
                                                    <div class="text-centered text-white">
                                                        <h4 class="st-1 lh"> Supplier </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 col-5 user-image bg-card-1 mb-2">
                                            <a [routerLink]="['/asset-management-list']" routerLinkActive="router-link-active" >
                                                <div class="image-container">
                                                    <img width="100" class="top-right-img" src="assets/assets.png" alt="ghaly fadhillah">
                                                    <div class="text-centered text-white">
                                                        <h4 class="st-1 lh"> Daftar Asset </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 col-5 user-image bg-card-1 mb-2">
                                            <a [routerLink]="['/asset-management-book-list']" routerLinkActive="router-link-active" >
                                                <div class="image-container">
                                                    <img height="100" class="top-right-img" src="assets/booking.png" alt="ghaly fadhillah">
                                                    <div class="text-centered text-white">
                                                        <h4 class="st-1 lh"> Ketersediaan Asset </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 col-5 user-image bg-card-1 mb-2">
                                            <a [routerLink]="['/asset-management-book-attendance']" routerLinkActive="router-link-active" >
                                                <div class="image-container">
                                                    <img height="100" class="top-right-img" src="assets/attendance.png" alt="ghaly fadhillah">
                                                    <div class="text-centered text-white">
                                                        <h4 class="st-1 lh"> Kehadiran </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

