import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimeAgoPipe } from "@services/ago.pipe";

import { FeatherIconsComponent } from "./shared-layout/feather-icons/feather-icons.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ContentComponent } from "./content/content.component";
import { MyAccountComponent } from "./header/sub-header/my-account/my-account.component";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./header/sub-header/swiper/swiper.component";
import { FloatingMenuComponent } from "./floating-menu/floating-menu.component";

import { LayoutService } from "./layout.service";
import { SidebarService } from "./sidebar/sidebar.service";
import { MyUrlListComponent } from './floating-menu/modal/my-url-list/my-url-list.component';

@NgModule({
  declarations: [
    FeatherIconsComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    MyAccountComponent,
    SwiperComponent,
    FloatingMenuComponent,
    TimeAgoPipe,
    MyUrlListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,],
  providers: [SidebarService, LayoutService],
  exports: [FeatherIconsComponent, FormsModule, ReactiveFormsModule, SwiperModule],
})
export class LayoutModule {}
