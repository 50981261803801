import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  allowedDomains = ['127.0.0.1', 'alloweddomain1.com', 'alloweddomain2.com'];

  ngOnInit(): void {
    if (window.top !== window.self) {
      const parentDomain = new URL(document.referrer).hostname;
      if (!this.allowedDomains.includes(parentDomain)) {
        document.body.innerHTML = 'Embedding not allowed.';
      }
    }
  }
}
