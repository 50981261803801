import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-access',
  templateUrl: './sso-access.component.html',
  styleUrls: ['./sso-access.component.scss']
})
export class SsoAccessComponent {
  constructor() { }

  ngOnInit(): void {
    this.loadScript('assets/sso.js');
  }

  loadScript(url: string): void {
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);
  }
}
