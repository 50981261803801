export const env = {
  v1_API: "https://ais.pertanian.go.id/be/v1/",
  rs_API: "https://ais.pertanian.go.id/be/rs/",
  asset_API: "https://ais.pertanian.go.id/be/",
  scraperAPI: "https://ais.pertanian.go.id/py/",
  // v1_API: "http://localhost:8181/be/v1/",
  // rs_API: "http://localhost:8080/be/rs/",
  // asset_API: "http://localhost:8181/be/",
  TBtwnT_kX: "6Lffx0AnAAAAADLLQ5mUSvefcuOcmRnTBtwnT_kX",
  qu2h67l8: "283775411557-q5ivulv3ikgdiqu2h67l8emluud2e4q7.apps.googleusercontent.com",
  sort: "<i class='fa fa-sort'></i>",
};
