import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { UserService } from '@services/user.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-refs',
  templateUrl: './refs.component.html',
  styleUrls: ['./refs.component.scss']
})
export class RefsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stgService: StorageService,
    private usrService: UserService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const pptAccessKey = params['ppt_access_key'];
      if (pptAccessKey) {
        this.verifySession(pptAccessKey);
      } else {
        console.error('ppt_access_key not found in query params');
      }
    });
  }

  showSuccessMessage(message: string): void {
    Swal.fire('Berhasil', message, 'success');
  }

  verifySession(pptAccessKey: string): void {
    this.usrService.verifySSO(pptAccessKey).subscribe({
      next: (res) => {
        console.log(res);
        this.stgService.saveUser(res);
        this.showSuccessMessage('Login berhasil');
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
}
