import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, catchError, Observable, Subject, tap } from "rxjs";
import { env } from "@env/env";
import { StorageService } from "./storage.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CrudService {
  private actionLabelSubject = new BehaviorSubject<string>("");
  private actionLabelSubjectSection1 = new BehaviorSubject<string>("");
  private actionLabelSubjectSection2 = new BehaviorSubject<string>("");
  private actionLabelSubjectSection3 = new BehaviorSubject<string>("");

  private dataIDSubject = new BehaviorSubject<string>("");
  private dataIDSubjectSection1 = new BehaviorSubject<string>("");
  private dataIDSubjectSection2 = new BehaviorSubject<string>("");
  private dataIDSubjectSection3 = new BehaviorSubject<string>("");

  private loadDataSubject = new Subject<void>();
  private loadDataSubjectSection1 = new Subject<void>();
  private loadDataSubjectSection2 = new Subject<void>();
  private loadDataSubjectSection3 = new Subject<void>();

  private visible: boolean = false;
  private visibleSection1: boolean = false;
  private visibleSection2: boolean = false;
  private visibleSection3: boolean = false;

  public visibleChanged = new EventEmitter<boolean>();
  public visibleSection1Changed = new EventEmitter<boolean>();
  public visibleSection2Changed = new EventEmitter<boolean>();
  public visibleSection3Changed = new EventEmitter<boolean>();

  public loadData$ = this.loadDataSubject.asObservable();
  public loadDataSection1$ = this.loadDataSubjectSection1.asObservable();
  public loadDataSection2$ = this.loadDataSubjectSection2.asObservable();
  public loadDataSection3$ = this.loadDataSubjectSection3.asObservable();

  public e = env;

  constructor(
    private http: HttpClient,
    private stgService: StorageService,
  ) {}

  private getHeaders(): HttpHeaders {
    const user = this.stgService.getUser(true);
    return new HttpHeaders({
      // "Content-Type": "application/json",
      Authorization: `Bearer ${user.data.token}`,
    });
  }

  private getHeadersNoAuth(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  public exportData(field: string): Observable<any> {
    return this.http.get(env.v1_API + field + "-export", {
      headers: this.getHeaders(),
      responseType: "blob",
    });
  }

  public getRoleModules(roleId: string): Observable<any> {
    return this.http.get<any>(`${env.v1_API}role-module?role_id=${roleId}`, {
      headers: this.getHeaders(),
    });
  }

  public checkModuleAccess(slug: string): Observable<any> {
    const user = this.stgService.getUser(true);
    return this.http.get<any>(`${env.v1_API}module-access?slug=${slug}&role=${user.data.role_name}`, {
      headers: this.getHeaders(),
    });
  }

  public updateRoleModule(roleId: string, moduleId: string, add: boolean): Observable<any> {
    const payload = { role_id: roleId, module_id: moduleId };
    return this.http.post<any>(`${env.v1_API}role-module-update?add=${add}`, payload, {
      headers: this.getHeaders(),
    });
  }

  public getList(field: string, filter: any): Observable<any> {
    let params = new HttpParams();
    params = params.set("search", filter.search);
    params = params.set("sort_by", filter.sortBy);
    params = params.set("sort_order", filter.sortOrder);
    params = params.set("page", filter.page);
    params = params.set("page_size", filter.pageSize);
    params = params.set("chart_data_id", filter.chart_data_id);

    return this.http.get(env.v1_API + field + "-table", {
      headers: this.getHeaders(),
      params: params,
    });
  }

  public getMasterSlaveList(key: string, filter: any): Observable<any> {
    let params = new HttpParams();
    params = params.set("search", filter.search);
    params = params.set("sort_by", filter.sortBy);
    params = params.set("sort_order", filter.sortOrder);
    params = params.set("page", filter.page);
    params = params.set("page_size", filter.pageSize);
    params = params.set("key", key);

    return this.http.get(env.v1_API + "data-list-ms", {
      params: params,
    });
  }

  public getListOnly(field: string, id: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("mid", id);
    return this.http.get(env.v1_API + field + "-list", {
      headers: this.getHeaders(),
      params: params,
    });
  }

  public getListNA(field: string): Observable<any> {
    return this.http.get(env.v1_API + field + "-list", {
      headers: this.getHeadersNoAuth(),
    });
  }

  public updateCategory(field: string, form: any): Observable<any> {
    return this.http.post(env.v1_API + field + "-create-relationship", form, {
      headers: this.getHeaders(),
    });
  }

  public deleteCategory(field: string, modID: string): Observable<any> {
    const options = {
      headers: this.getHeaders(),
      body: { module_id: modID },
    };

    return this.http.delete(env.v1_API + field + "-delete-relationship", options);
  }

  public isExist(field: string, key: string, val: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("key", key);
    params = params.set("val", val);
    return this.http.get(env.v1_API + field + "-exist", {
      headers: this.getHeaders(),
      params: params,
    });
  }

  public isAccess(field: string, endpoint: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("endpoint", endpoint);
    return this.http.get(env.v1_API + field + "-access", {
      params: params,
    });
  }

  public getDataByID(field: string, id: string): Observable<any> {
    const requestBody = { id: id };

    return this.http.post(env.v1_API + field + "-id", requestBody, {
      headers: this.getHeaders(),
    });
  }

  public createData(field: string, form: any): Observable<any> {
    return this.http.post(env.v1_API + field + "-create", form, {
      headers: this.getHeaders(),
    });
  }

  public deleteData(field: string, ids: string[]): Observable<any> {
    const options = {
      headers: this.getHeaders(),
      body: { ids: ids },
    };

    return this.http.delete(env.v1_API + field + "-delete", options);
  }

  public deleteDataWithAssets(field: string, ids: string[], assets: string[]): Observable<any> {
    const options = {
      headers: this.getHeaders(),
      body: { ids: ids, assets: assets, },
    };

    return this.http.delete(env.v1_API + field + "-delete", options);
  }

  public saveData(opt: number, field: string, form: any, id?: string): Observable<any> {
    const observable = id ? this.updateData(field, form) : this.createData(field, form);
    return observable.pipe(
      tap((res) => {
        if (res.status == 200) {
          this.toggleVisible(opt);
          this.triggerLoadData(opt);
          if (opt > 1) {
            this.triggerLoadData(opt-1);
          }
        } else {
          throw new Error(res.message);
        }
      }),
      catchError((err) => {
        throw new Error(err.error.message.error);
      })
    );
  }

  public saveImg(field: string, form: any): Observable<any> {
    return this.http.post(env.v1_API + field + "-image", form, {
      headers: this.getHeaders(),
    });
  }

  public updateData(field: string, form: any): Observable<any> {
    return this.http.put(env.v1_API + field + "-update", form, {
      headers: this.getHeaders(),
    });
  }

  public validateForm(form: any, fields: string[]): boolean {
    for (const field of fields) {
      if (typeof form[field] === 'string') {
        const trimmedValue = form[field].trim();
        if (trimmedValue === '') {
          Swal.fire({
            text: "Silahkan input " + field,
            icon: "warning",
          }); return false;
        }
      } else if (form[field] === null || form[field] === undefined) {
        Swal.fire({
          text: "Silahkan input " + field,
          icon: "warning",
        }); return false;
      }
    } return true;
  }

  public toggleVisible(opt: number) {
    switch (opt) {
      case 1:
        this.visible = !this.visible;
        this.visibleChanged.emit(this.visible);
      case 2:
        this.visibleSection1 = !this.visibleSection1;
        this.visibleSection1Changed.emit(this.visibleSection1);
      case 3:
        this.visibleSection2 = !this.visibleSection2;
        this.visibleSection2Changed.emit(this.visibleSection2);
      case 4:
        this.visibleSection3 = !this.visibleSection3;
        this.visibleSection3Changed.emit(this.visibleSection3);
    }
  }

  public triggerLoadData(opt: number) {
    switch (opt) {
      case 1:
        this.loadDataSubject.next();
      case 2:
        this.loadDataSubjectSection1.next();
      case 3:
        this.loadDataSubjectSection2.next();
      case 4:
        this.loadDataSubjectSection3.next();
    }
  }

  public setLabel(opt: number, label: string) {
    switch (opt) {
      case 1:
        this.actionLabelSubject.next(label);
      case 2:
        this.actionLabelSubjectSection1.next(label);
      case 3:
        this.actionLabelSubjectSection2.next(label);
      case 4:
        this.actionLabelSubjectSection3.next(label);
    }
  }

  public getLabel(opt: number): Observable<string> {
    switch (opt) {
      case 1:
        return this.actionLabelSubject.asObservable();
      case 2:
        return this.actionLabelSubjectSection1.asObservable();
      case 3:
        return this.actionLabelSubjectSection2.asObservable();
      case 4:
        return this.actionLabelSubjectSection3.asObservable();
    }
  }

  public setID(opt: number, id: string) {
    switch (opt) {
      case 1:
        this.dataIDSubject.next(id);
      case 2:
        this.dataIDSubjectSection1.next(id);
      case 3:
        this.dataIDSubjectSection2.next(id);
      case 4:
        this.dataIDSubjectSection3.next(id);
    }
  }

  public getID(opt: number): Observable<string> {
    switch (opt) {
      case 1:
        return this.dataIDSubject.asObservable();
      case 2:
        return this.dataIDSubjectSection1.asObservable();
      case 3:
        return this.dataIDSubjectSection2.asObservable();
      case 4:
        return this.dataIDSubjectSection3.asObservable();
    }
  }

  public apiPreview(form: any): Observable<any> {
    return this.http.post(env.v1_API + "module-detail-api-preview", form, {
      headers: this.getHeaders(),
    });
  }
}
